import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useCustomTheme } from "../../assets/themes/themes";
import Arrow from "../../assets/images/message.png";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";

function ContactUsScreen() {
  const { COLORS, isMobile } = useCustomTheme();
  const controls = useAnimation();
  const form: any = useRef();
  const notify = () =>
    toast("Submit Successfully! We will meet shortly.", { type: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    to_name: "",
    from_name: "",
    message: "",
  });

  useEffect(() => {
    const timestamp = localStorage.getItem("formSubmitTime");
    if (timestamp) {
      const timePassed = Date.now() - parseInt(timestamp, 10);
      if (timePassed < 48 * 60 * 60 * 1000) {
        setIsDisabled(true);
      }
    }
  }, []);

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (
      isDisabled ||
      !formData.to_name ||
      !formData.from_name ||
      !formData.message
    ) {
      return;
    }
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_nz1dnbb",
        "template_w0z3u62",
        form.current,
        "JOMphylOCa_UQxkWz"
      )
      .then(
        (result) => {
          notify();
          setIsDisabled(true);
          localStorage.setItem("formSubmitTime", Date.now().toString());
          setFormData({
            to_name: "",
            from_name: "",
            message: "",
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 3, delay: 0.3 },
        });
      } else {
        controls.start({
          opacity: 0,
          y: -500,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  return (
    <Box sx={{ background: COLORS.LIGHT_WHITE }}>
      <Box
        p={5}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography
          fontSize={isMobile ? 20 : 30}
          textAlign={"center"}
          color={COLORS.ORANGE}
          fontFamily="Poppins, sans-serif"
        >
          Contact <span style={{ color: COLORS.PURPLE }}>Us</span>
        </Typography>

        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            p: 5,
            backgroundColor: COLORS.WHITE,
            mt: 5,
            minWidth: isMobile ? "100%" : "80%",
          }}
        >
          <form
            ref={form}
            onSubmit={sendEmail}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              id="standard-basic"
              name="to_name"
              label="Full Name"
              variant="standard"
              value={formData.to_name}
              onChange={handleInputChange}
              required
            />
            <TextField
              id="standard-basic"
              label="Email Address"
              variant="standard"
              name="from_name"
              sx={{ mt: 2 }}
              type="email"
              value={formData.from_name}
              onChange={handleInputChange}
              required
            />
            <TextField
              id="standard-basic"
              label="Message"
              variant="standard"
              multiline
              rows={4}
              sx={{ mt: 2 }}
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <Button
                type="submit"
                sx={{
                  background: COLORS.CREAM,
                  color: COLORS.PURPLE,
                  borderRadius: isMobile ? 10 : 80,
                  width: isMobile ? 150 : 220,
                  height: isMobile ? 30 : 50,
                  fontSize: isMobile ? 12 : 18,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
                disabled={isDisabled || isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Send Message"}
              </Button>
              <ToastContainer />
            </Box>
          </form>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <motion.img
                animate={controls}
                initial={{ opacity: 0, y: -100 }}
                src={Arrow}
                style={{
                  width: isMobile ? "100%" : "100%",
                  height: isMobile ? 150 : 250,
                  objectFit: "contain",
                }}
                alt="arrow"
              />
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export default ContactUsScreen;
