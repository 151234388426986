import { Fragment } from "react";
import AllRoutes from "./Routes";
import AboutThisCourseScreen from "./pages/AboutThisCourse/AboutThisCourse";
import ProjectsOpportunitiesScreen from "./pages/ProjectsOpportunities/ProjectsOpportunities";
import HowItsWorksScreen from "./pages/HowitWorks/HowItsWorks";
import ContactUsScreen from "./pages/ContactUs/ContactUs";
import FooterScreen from "./pages/Footer/Footer";
import OurServicesScreen from "./pages/ourServices/OurServices";

function App() {
  return (
    <Fragment>
      <AllRoutes />
      <div id="bootcamp-program">
        <OurServicesScreen />
      </div>
      <div id="about-this-course">
        <AboutThisCourseScreen />
      </div>
      <div id="project-opportunities">
        <ProjectsOpportunitiesScreen />
      </div>
      <div id="how-it-works">
        <HowItsWorksScreen />
      </div>
      <div id="contact-us">
        <ContactUsScreen />
      </div>
      <FooterScreen />
    </Fragment>
  );
}

export default App;
