import { Box, Grid, Typography } from "@mui/material";
import { useCustomTheme } from "../../assets/themes/themes";
import VIDEO_CLIP from "../../assets/images/nextGenCodersProgram.mp4";
import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";

function HowItsWorksScreen() {
  const { COLORS, isMobile } = useCustomTheme();
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 3, delay: 0.3 },
        });
      } else {
        controls.start({
          opacity: 0,
          x: 0,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  return (
    <Box sx={{ backgroundColor: COLORS.LIGHT_WHITE }}>
      <Box p={5}>
        <Typography
          fontSize={isMobile ? 20 : 30}
          color={COLORS.PURPLE}
          sx={{ textAlign: "center" }}
          fontFamily="Poppins, sans-serif"
        >
          How it{" "}
          <span
            style={{ color: COLORS.ORANGE, fontFamily: "Poppins, sans-serif" }}
          >
            works
          </span>
        </Typography>
        <Grid spacing={2} mt={5} container>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <motion.video
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              src={VIDEO_CLIP}
              style={{
                width: isMobile ? 300 : 500,
                height: isMobile ? 200 : 300,
                objectFit: "contain",
                marginTop: isMobile ? 5 : 10,
              }}
              controls
            >
              How it works
            </motion.video>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            mt={isMobile ? 3 : 0}
          >
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 16 : 20}
              fontFamily="Poppins, sans-serif"
            >
              Practice with Question{" "}
              <span
                style={{
                  color: COLORS.PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Papers and Examinations
              </span>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 13 : 16}
              fontFamily="Poppins, sans-serif"
            >
              <span
                style={{
                  color: COLORS.LIGHT_PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                To ensure thorough preparation, you will have access to practice
                question papers and mock examinations. This will help you:
              </span>

              <ul>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Reinforce learning and gauge your understanding
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Prepare for real-world problem-solving scenarios
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Build confidence in handling coding interviews and
                    assessments
                  </span>
                </li>
              </ul>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 16 : 20}
              fontFamily="Poppins, sans-serif"
            >
              Comprehensive Notes{" "}
              <span
                style={{
                  color: COLORS.PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                for Every Programming Language
              </span>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 13 : 16}
              fontFamily="Poppins, sans-serif"
            >
              <span
                style={{
                  color: COLORS.LIGHT_PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                In addition to the classes, I'm providing detailed notes for
                every programming language covered in the course. These notes
                will help you:
              </span>
              <ul>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Quickly review key concepts and syntax
                  </span>{" "}
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Understand the nuances of each language
                  </span>{" "}
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Serve as a handy reference for troubleshooting and
                    development
                  </span>{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HowItsWorksScreen;
