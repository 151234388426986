import { Box, Card, Typography } from "@mui/material";
import { useCustomTheme } from "../../assets/themes/themes";
import { aboutThisCourse } from "../../db";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

function AboutThisCourseScreen() {
  const { COLORS, isMobile, isTablet } = useCustomTheme();
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 2, delay: 0.2 },
        });
      } else {
        controls.start({
          opacity: 0,
          y: 500,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  return (
    <Box sx={{ background: COLORS.LIGHT_WHITE }}>
      <Box p={isMobile ? 5 : 0}>
        <Typography
          fontSize={isMobile ? 20 : 30}
          textAlign={"center"}
          color={COLORS.PURPLE}
          fontFamily="Poppins, sans-serif"
        >
          About{" "}
          <span
            style={{ color: COLORS.ORANGE, fontFamily: "Poppins, sans-serif" }}
          >
            This Course
          </span>
        </Typography>
        <Box
          display={"flex"}
          mt={isMobile ? 3 : 5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <motion.div
            animate={controls}
            style={{ display: "flex", justifyContent: "center" }}
            initial={{ opacity: 0, y: 50 }}
          >
            <Card
              style={{
                width: isMobile ? 300 : isTablet ? "100%" : "90%",
                height: isMobile ? 500 : 500,
                marginTop: isMobile ? 5 : 5,
                boxShadow: `2px 1px 4px 2px ${COLORS.WHITE}`,
                scrollBehavior: "smooth",
                overflow: "scroll",
                scrollbarWidth: "none",
              }}
            >
              {aboutThisCourse.map(
                (item: { name: string; description: string }) => (
                  <Box key={item.name} sx={{ pl: 2, pt: 2, pb: 2 }}>
                    <Typography
                      fontSize={isMobile ? 16 : 20}
                      color={COLORS.PURPLE}
                      fontFamily="Poppins, sans-serif"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      fontSize={isMobile ? 14 : 16}
                      color={COLORS.LIGHT_PURPLE}
                      fontFamily="Poppins, sans-serif"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                )
              )}
            </Card>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutThisCourseScreen;
