import { Box, Card, Typography } from "@mui/material";
import { useCustomTheme } from "../../assets/themes/themes";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

type Service = {
  name: string;
  description: string;
  icon: JSX.Element;
};

function OurServicesScreen() {
  const { COLORS, isMobile, isTablet } = useCustomTheme();

  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 1, delay: 0.2 },
        });
      } else {
        controls.start({
          opacity: 0,
          x: 0,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  const services: Service[] = [
    {
      name: "Web Development",
      description:
        "Build and maintain websites with modern web technologies, ensuring responsive and user-friendly interfaces.",
      icon: (
        <WebAssetIcon
          sx={{
            width: 50,
            height: 50,
            borderRadius: 99,
            boxShadow: `2px 1px 8px 1px ${COLORS.CREAM}`,
          }}
        />
      ),
    },
    {
      name: "Android Development",
      description:
        "Create feature-rich Android applications with a focus on performance and usability.",
      icon: (
        <AndroidIcon
          sx={{
            width: 50,
            height: 50,
            borderRadius: 99,
            boxShadow: `2px 1px 8px 1px ${COLORS.CREAM}`,
          }}
        />
      ),
    },
    {
      name: "iOS Development",
      description:
        "Develop cutting-edge iOS applications that deliver a seamless user experience on all Apple devices.",
      icon: (
        <AppleIcon
          sx={{
            width: 50,
            height: 50,
            borderRadius: 99,
            boxShadow: `2px 1px 8px 1px ${COLORS.CREAM}`,
          }}
        />
      ),
    },
  ];

  return (
    <Box sx={{ background: COLORS.LIGHT_WHITE }}>
      <Box p={isMobile ? 2 : 5}>
        <Typography
          fontSize={isMobile ? 20 : 30}
          color={COLORS.PURPLE}
          sx={{ textAlign: "center" }}
          mt={isMobile ? 3 : 5}
          fontFamily="Poppins, sans-serif"
        >
          All-In-One{" "}
          <span style={{ color: COLORS.ORANGE }}>BootCamp Program.</span>
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            fontSize={isMobile ? 14 : 16}
            color={COLORS.LIGHT_PURPLE}
            fontFamily="Poppins, sans-serif"
            p={2}
            sx={{ textAlign: "center", width: isMobile ? 300 : 900 }}
          >
            Our comprehensive BootCamp Program offers expert-led training in
            Web, Android, and iOS development, providing you with the skills to
            succeed in today's tech-driven world. Learn hands-on, practical
            skills to build, deploy, and maintain modern applications across
            multiple platforms.
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={isMobile ? "column" : isTablet ? "column" : "row"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          {services.map((item: Service) => (
            <motion.div
              key={item.name}
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
              transition={{ duration: 0.5, ease: "easeOut" }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={{
                  width: isMobile ? 250 : isTablet ? "100%" : "90%",
                  height: isMobile ? 250 : 300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  p: 2,
                  mt: isMobile ? 5 : 10,
                  mb: isMobile ? 5 : 10,
                  boxShadow: `2px 1px 4px 2px ${COLORS.WHITE}`,
                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: `2px 1px 12px 3px ${COLORS.PURPLE}`,
                  },
                }}
              >
                <Typography>{item.icon}</Typography>
                <Typography
                  fontSize={isMobile ? 16 : 20}
                  color={COLORS.PURPLE}
                  sx={{ textAlign: "center" }}
                  fontFamily="Poppins, sans-serif"
                >
                  {item.name}
                </Typography>
                <Typography
                  fontSize={isMobile ? 14 : 16}
                  color={COLORS.LIGHT_PURPLE}
                  mt={2}
                  sx={{ textAlign: "center" }}
                  fontFamily="Poppins, sans-serif"
                >
                  {item.description}
                </Typography>
              </Card>
            </motion.div>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default OurServicesScreen;
