import { Box, Typography } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useCustomTheme } from "../../assets/themes/themes";

function FooterScreen() {
  const { COLORS, isMobile } = useCustomTheme();
  return (
    <Box sx={{ backgroundColor: COLORS.LIGHT_BLACK }}>
      <Box
        p={2}
        display={"flex"}
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={"space-around"}
        alignItems={isMobile ? "center" : "baseline"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} mt={5} alignItems={"center"}>
            <span>
              <PhoneInTalkIcon color="info" />
            </span>
            <Typography
              ml={2}
              fontSize={isMobile ? 14 : 16}
              color={COLORS.LIGHT_WHITE}
              fontFamily="Poppins, sans-serif"
            >
              {" "}
              +91 9259457900
            </Typography>
          </Box>
          <Box display={"flex"} mt={2} alignItems={"center"}>
            <span>
              <EmailIcon color="info" />
            </span>
            <Typography
              ml={2}
              fontSize={isMobile ? 14 : 16}
              color={COLORS.LIGHT_WHITE}
              fontFamily="Poppins, sans-serif"
            >
              {" "}
              nextgencoderprogram@gmail.com
            </Typography>
          </Box>
          <Box display={"flex"} mt={2} alignItems={"center"}>
            <span>
              <LocationOnIcon color="info" />
            </span>
            <Typography
              ml={2}
              fontSize={isMobile ? 14 : 16}
              color={COLORS.LIGHT_WHITE}
              fontFamily="Poppins, sans-serif"
            >
              {" "}
              Country India, Hapur
            </Typography>
          </Box>
        </Box>
        {/* <Box m={2} display={"flex"} alignItems={"center"}>
          <Box p={2}>
            <LinkedInIcon color="info" />
          </Box>
          <Box p={2}>
            <FacebookIcon color="info" />
          </Box>
          <Box p={2}>
            <InstagramIcon color="info" />
          </Box>
        </Box> */}
      </Box>
      <Box p={2}>
        <Typography
          fontFamily="Poppins, sans-serif"
          color={COLORS.WHITE}
          textAlign={"center"}
        >
          @copyright 2024 Provided By NextGen Coders Program
        </Typography>
      </Box>
    </Box>
  );
}

export default FooterScreen;
