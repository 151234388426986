import { Box, Grid, Typography } from "@mui/material";
import { useCustomTheme } from "../../assets/themes/themes";
import TeacherImg from "../../assets/images/teacher.png";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

function ProjectsOpportunitiesScreen() {
  const { COLORS, isMobile } = useCustomTheme();
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 2, delay: 0.2 },
        });
      } else {
        controls.start({
          opacity: 0,
          y: 0,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls]);

  return (
    <Box sx={{ background: COLORS.LIGHT_WHITE }}>
      <Box p={5}>
        <Typography
          color={COLORS.ORANGE}
          fontSize={isMobile ? 20 : 30}
          textAlign={"center"}
          fontFamily="Poppins, sans-serif"
        >
          Projects{" "}
          <span
            style={{ color: COLORS.PURPLE, fontFamily: "Poppins, sans-serif" }}
          >
            Opportunities
          </span>
        </Typography>
        <Grid container>
          <Grid
            xs={12}
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            mt={5}
          >
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 16 : 20}
              fontFamily="Poppins, sans-serif"
            >
              Project Opportunities for Beginners{" "}
              <motion.span
                initial={{ opacity: 0, dy: 0 }}
                animate={{
                  opacity: 1,
                  x: 100,
                  transition: {
                    delay: 0.2,
                    duration: 2,
                  },
                }}
                style={{ color: COLORS.PURPLE }}
              >
                During the course, beginners will work on multiple projects to
                solidify their skills:
              </motion.span>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 13 : 16}
              fontFamily="Poppins, sans-serif"
            >
              <ul>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    HTML5 Projects:
                  </span>{" "}
                  10+ projects
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    CSS Projects:
                  </span>{" "}
                  10+ projects
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    JavaScript Projects:
                  </span>{" "}
                  10+ projects
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    React.js & React Native Projects:
                  </span>{" "}
                  10+ projects
                </li>
              </ul>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 16 : 20}
              fontFamily="Poppins, sans-serif"
            >
              Solving and Debugging{" "}
              <motion.span
                initial={{ opacity: 0, dy: 0 }}
                animate={{
                  opacity: 1,
                  x: 100,
                  transition: {
                    delay: 0.3,
                    duration: 3,
                  },
                }}
                style={{
                  color: COLORS.PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Problems in Various Languages
              </motion.span>
            </Typography>
            <Typography
              color={COLORS.ORANGE}
              fontSize={isMobile ? 13 : 16}
              fontFamily="Poppins, sans-serif"
            >
              <span
                style={{
                  color: COLORS.LIGHT_PURPLE,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                As part of the course, you will also learn essential skills for
                solving and debugging problems across different programming
                languages. This includes:
              </span>
              <ul>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Identifying common errors and understanding error messages
                  </span>{" "}
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Using debugging tools in IDEs like VS Code
                  </span>{" "}
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Writing effective test cases
                  </span>{" "}
                </li>
                <li>
                  <span
                    style={{
                      color: COLORS.PURPLE,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Debugging techniques specific to languages covered in the
                    course
                  </span>{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid xs={12} md={6} display={"flex"} justifyContent={"center"}>
            <motion.img
              animate={controls}
              initial={{ opacity: 0, y: -100 }}
              style={{
                width: isMobile ? 300 : "100%",
                height: isMobile ? 300 : 700,
                objectFit: "contain",
              }}
              src={TeacherImg}
              alt="teacher"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectsOpportunitiesScreen;
