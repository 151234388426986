import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Colors = {
  WHITE: string;
  BLACK: string;
  GREY: string;
  CREAM: string;
  BLUE: string;
  ORANGE: string;
  LIGHT_BLACK: string;
  PURPLE: string;
  LIGHT_BLUE: string;
  LIGHT_WHITE: string;
  LIGHT_PURPLE: string;
};

type Breakpoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  COLORS: Colors;
};

export const useCustomTheme = (): Breakpoints => {
  const COLORS: Colors = {
    WHITE: "#ffffff",
    BLACK: "#000000",
    GREY: "#cccccc",
    CREAM: "#FFF2E1",
    BLUE: "#464646",
    ORANGE: "#F48C06",
    LIGHT_BLACK: "#252641",
    PURPLE: "#2F327D",
    LIGHT_BLUE: "#23BDEE",
    LIGHT_WHITE: "#F5F5FC",
    LIGHT_PURPLE: "#696984",
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("md", "sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return { isMobile, isTablet, isDesktop, COLORS };
};
